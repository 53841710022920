import axios from 'axios';

export const getAnnonces = async (params = "") => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/annonces${params}`;

    return axios.get(ApiUrl).then((response) => response.data);
}

export const createAnnonce = async (data) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/annonces`;

    return axios.post(ApiUrl, data).then((response) => response.data);
}

export const updateAnnonce = async (id, data) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/annonces/${id}`;
    
    return axios.put(ApiUrl, data).then((response) => response.data);
}

export const updateAnnonceImage = async (id, imageUpload) => {
    const formData = new FormData();
    formData.append('file', imageUpload);

    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/annonces/${id}/image`;

    return axios.post(ApiUrl, formData).then((response) => response.data);
}

export const deleteAnnonce = async (id) => {
    const ApiUrl = `${process.env.REACT_APP_PUBLIC_API}/annonces/${id}`;
    
    return axios.delete(ApiUrl).then((response) => response.data);
}