import {useContext, useEffect, useState} from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { Grid, Badge } from '@mui/material';
import Container from "@mui/material/Container";
import { useNavigate } from 'react-router-dom';
import { getCustomersAds } from 'src/service/customerApi';
import { getConversation } from 'src/service/conversationApi';
import AuthContext from 'src/contexts/AuthContext';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const ButtonStyle = styled('button')(({ theme }) => ({
  textAlign: 'center',
  padding: '50px',
  backgroundColor: '#e7f2f3',
  borderRadius: '20px',
  border: 'none',
  textTransform: 'uppercase',
  minWidth: '100%',
  '&:hover': {
    backgroundColor: "#009faa",
    color: "white"
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);

  const [numberOfMessage, setNumberOfMessage] = useState(0)
  const [numberOfAExpedier, setNumberOfAExpedier] = useState(0)
  const { customerId } = useContext(AuthContext)

  const getNumberOfData = async () => {
    const data = await getConversation(`?annonce.customer=${customerId}`)

    const dataExp = await getCustomersAds(customerId, '?status=A_EXPEDIER');

    let notResponded = data.filter((conv) => conv.isPending === true);
    setNumberOfMessage(notResponded.length)
    setNumberOfAExpedier(dataExp.length)
  }

  useEffect(() => {
      if (window.location.href ==  window.location.origin + '/dashboard/publish') {
        setIsActive1(true);setIsActive2(false);setIsActive3(false);setIsActive4(false)
      } else if (window.location.href == window.location.origin + '/dashboard/products/trouves') {
        setIsActive2(true);setIsActive1(false);setIsActive3(false);setIsActive4(false)
      } else if (window.location.href == window.location.origin + '/dashboard/messages') {
        setIsActive3(true);setIsActive1(false);setIsActive2(false);setIsActive4(false)
      } else if (window.location.href == window.location.origin + '/dashboard/products/aexpedie') {
        setIsActive4(true);setIsActive1(false);setIsActive2(false);setIsActive3(false)
      } else {
        setIsActive4(false);setIsActive1(false);setIsActive2(false);setIsActive3(false)
      }
      getNumberOfData()
  });

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Container>
          <Grid sx={12} container spacing={3}>
            <Grid item md={3}>
              <ButtonStyle
              onClick={() => navigate('/dashboard/publish') }
              style={{
                backgroundColor: isActive1 ? '#009faa' : '#e7f2f3',
                color: isActive1 ? 'white' : 'black',
              }}>
                Publier
              </ButtonStyle>
            </Grid>
            <Grid item md={3}>
              <ButtonStyle
                  onClick={() => navigate('/dashboard/products/trouves')}
                  style={{
                backgroundColor: isActive2 ? '#009faa' : '#e7f2f3',
                color: isActive2 ? 'white' : 'black',
              }}>
                Objets trouvés
              </ButtonStyle>
            </Grid>
            <Grid item md={3}>
              <Badge badgeContent={numberOfMessage} max={999} color="error">
                <ButtonStyle
                    onClick={() => navigate('/dashboard/messages')}
                    style={{
                      backgroundColor: isActive3 ? '#009faa' : '#e7f2f3',
                      color: isActive3 ? 'white' : 'black',
                    }}>
                  Messages reçus
                </ButtonStyle>
              </Badge>
            </Grid>
            <Grid item md={3}>
              <Badge badgeContent={numberOfAExpedier} color="error">
                <ButtonStyle
                    onClick={() => navigate('/dashboard/products/aexpedie')}
                    style={{
                      backgroundColor: isActive4 ? '#009faa' : '#e7f2f3',
                      color: isActive4 ? 'white' : 'black',
                    }}>
                  A expedier
                </ButtonStyle>
              </Badge>
            </Grid>
          </Grid>
        </Container>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
