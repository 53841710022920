import { Box, Button, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Chat from 'src/components/Chat/Chat';
import Adetails from './Adetails';
import { styled } from '@mui/material/styles';
import UpdateAds from './UpdateAds';
import PropTypes from 'prop-types';
import AdsTracking from 'src/components/AdsTracking/AdsTracking';
import PaiementTracking from 'src/components/PaiementTracking/PaiementTracking';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export default function AnnonceDetails({ selectedAnnonce, setSelectedAnnonce, categories, updateUserAds }) {
  const [value, setValue] = useState(0);
  const [selectedAnnonceStatus, setSelectedAnnonceStatus] = useState(selectedAnnonce.status);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Adetails annonce={selectedAnnonce} />
        </Grid>

        <Grid item xs={12} md={6} sx={{alignItems: "center", display: "grid"}}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{margin: "50px auto 0 auto"}} variant="fullWidth">
              <Tab label="Discuter" {...a11yProps(0)} />
              <Tab label="Modifier" {...a11yProps(1)} />
              <Tab label="Suivi de paiement" {...a11yProps(2)} />
              {selectedAnnonceStatus !== "TROUVÉ" && (
                  <Tab label="Suivi de livraison" {...a11yProps(3)} />
              )}
          </Tabs>
          <Stack spacing={2}>
            <CustomTabPanel value={value} index={0}>
                <Chat annonce={selectedAnnonce} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <UpdateAds selectedAnnonce={selectedAnnonce} setSelectedAnnonce={setSelectedAnnonce} categories={categories} updateUserAds={updateUserAds}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <PaiementTracking annonce={selectedAnnonce} />
            </CustomTabPanel>
            {selectedAnnonceStatus !== "TROUVÉ" && (
              <>
                <CustomTabPanel value={value} index={3}>
                  <AdsTracking annonce={selectedAnnonce} />
                </CustomTabPanel>
              </>
            )}
          </Stack>
        </Grid>

      </Grid>
    </>
  );
}
