import React, { createContext, useContext, useEffect, useState } from 'react';
import CustomStepper from './FormComponents/CustomStepper';
import AnnonceContext from '../../../contexts/AnnonceContext';
import Page from 'src/components/Page';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { createAnnonce, updateAnnonceImage } from 'src/service/annonceApi';
import { notifySuccess, notifyErrorEmpty } from 'src/service/notificationService';

const Publier = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formAnnonce, setFormAnnonce] = useState({
    origin: 'TROUVÉ',
    status: 'TROUVÉ',
    ville: 'Les Trans Musicales, Rennes',
    placeDescription: 'Les Trans Musicales, Rennes, France',
  });
  const [currentCategory, setCurrentCategory] = useState();
  const [formAttributes, setFormAttributes] = useState([]);
  const [imageUpload, setImageUpload] = useState();
  const [date, setDate] = useState(new Date().toISOString());

  let navigate = useNavigate();

  useEffect(() => {
    setFormAnnonce({
      ...formAnnonce,

      annonceAttributes: formAttributes,
      date: date,
    });
  }, [formAttributes]);

  const checkStockage = () => {
    if (formAnnonce.stockage === undefined || formAnnonce.stockage === '')
      return false
    return true
  }

  const handleNotifySuccess = () => {
    navigate('/dashboard/products/trouves');
  }

  const handleSubmit = async () => {
    try {
      if (!checkStockage()) {
        notifyErrorEmpty("Boite")
        return
      }
      const response = await createAnnonce(formAnnonce);
      const annonceId = response.id;

      updateAnnonceImage(annonceId, imageUpload)
      notifySuccess('Annonce ajoutée avec succès', handleNotifySuccess)
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AnnonceContext.Provider
      value={{
        formAnnonce: formAnnonce,
        setFormAnnonce: setFormAnnonce,
        currentCategory: currentCategory,
        setCurrentCategory: setCurrentCategory,
        formAttributes: formAttributes,
        setFormAttributes: setFormAttributes,
        handleSubmit: handleSubmit,
        imageUpload: imageUpload,
        setImageUpload: setImageUpload,
        activeStep: activeStep,
        setActiveStep: setActiveStep,
        date: date,
        setDate: setDate,
      }}
    >
      <Page>
        <CustomStepper />
      </Page>
    </AnnonceContext.Provider>
  );
};

export default Publier;
